// i18next-extract-mark-ns-start products-vectis-boundary

import { VECTIS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductVectisBoundaryConditionsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							At the heart of every 3D CFD modelling lies the process of spatial discretization.
							This is typically a time-consuming area of modelling and one which impacts most the accuracy of the simulations.
							VECTIS provides capabilities helping users model space and motion accurately and quickly :
						</p>

						<ul>
							<li>Rapid import, repair and pre-processing for ICE simulations of CAD geometries and STL surface meshes</li>
							<li>Class-leading fully automated fast Cartesian, Tetrahedral and Polyhedral grid generation</li>
							<li>Multi-domain and sub-domain handling including unique Full Arbitrary Grid Interface (AGI)
								for non-conformal grid interfaces</li>
							<li>Realis Arbitrary Dynamic Mesh Cut–cell (ADMC) Finite Volume Method delivering accurate automated mesh generation
								without distortion or re-meshing on any type of grid</li>
							<li>Ability to work seamlessly with third party grid generation tools through CFD General Notation System (CGNS) interface</li>
						</ul>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="VECTIS automated meshing workflow" />
						</p>
					)}
				</div>

				<Aside>
					<Aside.Widget
						title="Capabilities and applications"
						className="widget__sibling__pages">
						<AssetList>
							{VECTIS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductVectisBoundaryConditionsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-vectis-boundary", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS.jpg" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_Meshing.png" }) {
		...imageBreaker
	}
}
`;

